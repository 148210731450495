<template>
<div ref="modalPortCheckIn" class="phone-modal phone-modal-port-search with-nav" :style="{ opacity: isPortCheckInModalOpen ? 1 : 0, bottom: modalPortCheckInHeight + 'px'}" :class="{active: isPortCheckInModalOpen}">
	<span class="modal-line"></span>
	<div class="modal-title-bar">
		<div class="icon-wrap">
			<CheckInIcon style="width: 25px; height: auto;" class="icon checkin-icon" />
			<span>Check-in</span>
		</div><!-- icon wrap -->
		<GreyCloseIcon @click="closePortCheckInModal" style="width: 31px; height: 31px;" class="icon close" />
	</div><!-- modal title bar -->
	<div class="section-gap-alt-small">
		<div class="info-card-pass">
			<div class="info-card-head with-image">
				<img class="bg" alt="The Mission to Seafarers" src="@/assets/dashboard-header.jpg" />
				<div class="info-card-img-text">
					<p class="big">Vancouver</p>
					<p class="big lighter">Canada</p>
				</div>
			</div>
			<div class="info-card no-title">
				<div class="info-card-wrap">
					<div class="row">
						<div class="col full">
							<span class="eyebrow">Location</span>
							<p class="p-strong">401 East Waterfront Road, Vancouver, BC, V6A 4G9</p>
						</div>
					</div>
					<div class="row">
						<div class="col full">
							<span class="eyebrow">Local Time</span>
							<p class="p-strong">09:41 AM</p>
						</div>
					</div>
				</div>
				<Button icon="CheckInIcon" class="section-gap none-bottom btn btn-orange btn-tick btn-square btn-icon btn-icon-right" text="Check-in here"></Button>
			</div>
		</div><!-- info pass -->
	</div>
	<a class="text-link text-link-grey">I don't want to check-in here &raquo;</a>
</div><!-- modal -->
</template>

<script>
import Button from '@/components/Button.vue';
import GreyCloseIcon from '@/assets/close-grey.svg';
import CheckInIcon from '@/assets/check-in.svg';
export default {
name: 'PortCheckInOverlay',
	props: {
		subtitle: String,
		title: String,
		icon: String
	},
	data () {
		return {
			isPortCheckInModalOpen: false,
			modalPortCheckInHeight: null
		};
	},
	components: {
		Button,
		GreyCloseIcon,
		CheckInIcon
	},
	computed: {
		matchHeightModal () {
			return '-' + this.$refs.modalPortCheckIn.clientHeight;
		}
	},
	methods: {
        closePortCheckInModal () {
        	this.isPortCheckInModalOpen = !this.isPortCheckInModalOpen;
        }
    },
    mounted () {
		this.modalPortCheckInHeight = this.matchHeightModal;
	}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
	@import "@/assets/scss/_variables.scss";
	.modal-title-bar {
		.icon-wrap {
			svg {
				g, path {
					stroke-width: 2.5;
					stroke: $orange_dark;
				}
			}
			span {
				color: $orange_dark;
			}
		}
	}
</style>
