<template>
	<div class="check-in">
		<Nav />
		<span class="overlay" :class="{active: isMenuOpen}"></span>
		<div class="header header-standard">
			<HeaderNavBar back search />
		</div><!-- header -->
		<div class="header-image orange"></div><!-- header image -->
		<div class="content-wrap content-wrap-alt content-wrap-nav">
			<SectionTitle
				icon="CheckInIcon"
				title="Recently Visted Ports"
				colour="orange"
			/>
			<div class="section-gap section-port-list">
				<div class="section-gap section-port-list">
					<div
                        v-for="(checkIns, date) in recent"
                        :key="date"
                        class="port-list-wrap gap"
                    >
						<span class="post-list-sub">{{ date | moment('MMMM YYYY') }}</span>
	                    <PortListBlock
                            v-for="checkIn in checkIns"
                            :key="checkIn.id"
	                    	:port="checkIn.port.name"
	                    	:country="checkIn.port.country"
	                    	:date="checkIn.created_at | moment('Do MMMM YYYY')"
                            :id="checkIn.port.id"
                            :countryCode="checkIn.port.country_code"
	                    />
					</div><!-- port list wrap -->
				</div>
			</div>
		</div>
		<PortSearchOverlay title="Search to Check-in" />
		<PortCheckInOverlay />
		<PortCheckInConfirmOverlay />
		<PortCheckInSuccessOverlay />
		<Menu />
	</div>
</template>
<script>
	import HeaderNavBar from '@/components/HeaderNavBar.vue';
	import Nav from '@/components/Nav.vue';
	import Menu from '@/components/Menu.vue';
	import SectionTitle from '@/components/SectionTitle.vue';
	import PortListBlock from '@/components/PortListBlock.vue';
	import PortCheckInOverlay from '@/components/PortCheckInOverlay.vue';
	import PortCheckInConfirmOverlay from '@/components/PortCheckInConfirmOverlay.vue';
	import PortCheckInSuccessOverlay from '@/components/PortCheckInSuccessOverlay.vue';
	import PortSearchOverlay from '@/components/PortSearchOverlay.vue';
    import UserService from '@/services/UserService';
	export default {
		name: 'CheckInLog',
		components: {
			Nav,
			HeaderNavBar,
			Menu,
			SectionTitle,
			PortListBlock,
			PortSearchOverlay,
			PortCheckInOverlay,
			PortCheckInConfirmOverlay,
			PortCheckInSuccessOverlay
		},
		data () {
	        return {
                recent: []
	        };
	    },
	    methods: {
            getRecent () {
                UserService.getCheckInRecent().then((response) => {
                    this.recent = response.data;
                });
            }
        },
	    computed: {
			isMenuOpen () {
	            return this.$store.state.isMenuOpen;
	        }
		},
		mounted () {
            this.getRecent();
        }
	};
</script>
<style scoped lang="scss">
	@import "@/assets/scss/_variables.scss";
</style>
